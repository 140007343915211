.banner0{
    background-image: url(../../../public/images/banner/banner-0.jpg) !important;
}

.banner1{
    background-image: url(../../../public/images/banner/banner-1.jpg) !important;
}

.banner2{
    background-image: url(../../../public/images/banner/banner-2.jpg) !important;
}

.banner3{
    background-image: url(../../../public/images/banner/banner-3.jpg) !important;
}

.pageTitle{
    background-image: url(../../../public/images/background/page-title.jpg) !important;
}