.backGroundPicture{
    background-image: url(../../../public/images/background/page-title.jpg);
}

.sidebar1{
    background-image: url(../../../public/images/resource/sidebar-1.jpg);
}

.h3{
    color: black !important;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}